:root {
  --ps-primary-color: #ef2c34;
  --ps-primary-gradient-color: #d92129;
  --ps-primary-second-color: #10131c;
  --ps-secondary-color: #10131c;
  --ps-tertiary-color: #858585;
  --ps-white-color: #fff;
  --ps-title-font-family: 'Fahkwang', sans-serif;
  --ps-body-font-family: 'Space Grotesk', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --extendify--spacing--large: var(--wp--custom--spacing--large, clamp(2em, 8vw, 8em)) !important;
  --wp--preset--font-size--ext-small: 1rem !important;
  --wp--preset--font-size--ext-medium: 1.125rem !important;
  --wp--preset--font-size--ext-large: clamp(1.65rem, 3.5vw, 2.15rem) !important;
  --wp--preset--font-size--ext-x-large: clamp(3rem, 6vw, 4.75rem) !important;
  --wp--preset--font-size--ext-xx-large: clamp(3.25rem, 7.5vw, 5.75rem) !important;
  --wp--preset--color--black: #000 !important;
  --wp--preset--color--white: #fff !important
}

body {
  font-family: var(--ps-body-font-family);
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: var(--ps-tertiary-color);
  overflow-x: hidden;

  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scrollbar-hide::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0;
  font-family: var(--ps-title-font-family);
  color: var(--ps-secondary-color);
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}



.action-button-wrap {
  position: relative;
  margin: 0 0 0 auto;
}

.ps-services-slider .services-control-area {
  position: relative;
  display: flex;
  width: 210px;
  height: 40px;
  gap: 30px;
  align-self: flex-end;
  justify-content: end;
}

.ps-services-slider .swiper-container .services-swiper-pagination {
  display: block;
  position: relative;
  left: auto;
  bottom: auto !important;
  padding: 0;
  margin: 0;
  width: auto;
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  transform: none;
  align-self: center;
  line-height: normal;
}

.ps-services-slider .swiper-navigation-arrow {
  display: flex;
  gap: 10px;
  margin-right: 7px;
}

.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  width: auto;
  height: auto;
  background: none;
  opacity: 0;
  z-index: 2;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
}

.swiper-container .swiper-button-prev {
  left: 35px;
}

.ps-services-slider .services-control-area .swiper-button-next,
.ps-services-slider .services-control-area .swiper-button-prev {
  opacity: 1;
  position: relative;
  transform: none;
  top: auto;
}

.ps-services-slider .services-control-area .swiper-button-prev {
  left: auto;
  right: auto;
}

.ps-services-slider .services-control-area .swiper-button-next i,
.ps-services-slider .services-control-area .swiper-button-prev i {
  position: relative;
  background: #fff;
  width: 55px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #323232;
  opacity: 1;
  box-shadow: 0 5px 12px rgba(0, 0, 0, .1);
  border-radius: 7px;
}

.ps-services-slider .services-control-area .swiper-button-next {
  right: auto;
}

.ps-services-slider .services-control-area .swiper-button-next:hover i,
.ps-services-slider .services-control-area .swiper-button-prev:hover i {
  background: #042368;
  color: #fff;
  cursor: pointer;
}


.slider-container {
  position: relative;
  overflow: visible;
  width: 100%;
  display: flex;
}

.slide {
  min-width: 100%;
  flex: 1;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide h2 {
  color: white;
}

.slide.active {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

button.autoplay-btn {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 20px 40px;
  top: 60px;
}


section.why-society-prime {
  background-color: #fbf9f7;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  padding: 120px 0 120px 0;
}

.list {
  position: relative;
}

.list figure {
  position: absolute;
  left: 20%;
  top: -1%;
  width: 320px;
  height: auto;
  opacity: .12;
}

.list ul {
  list-style-type: none;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.list.icon-default li,
.list.icon-border li,
.list.icon-flat li {
  display: flex;
}

.list ul li {
  padding: 0;
  padding-bottom: 10px;
  padding-bottom: 15px;
}

.list.icon-default li i,
.list.icon-border li i,
.list.icon-flat li i {
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.list.icon-default li i {
  width: auto;
  font-size: 13px;
  color: var(--ps-primary-color);
  margin-right: 8px;
}

.section-title .title-tagline,
.list.icon-default li i {
  color: #042368;
}

.list ul li i {
  margin-right: 10px;
}

.list ul li span {
  align-self: center;
}

.wsp-list-container {
  max-height: 540px;
  overflow: hidden;
  overflow-y: auto;
}

.wsp-list-container:hover {
  cursor: n-resize;
}

.wsp-list {
  margin-bottom: 42px;
}

.wsp-list figure {
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
}

.site-header.header-fancy .main-navigation .navbar-nav>li:last-child a {
  color: rgb(255, 255, 255) !important;
  background: rgb(40, 67, 137);
  border-radius: 55px;
  padding: 10px 20px;
  display: inline-block;
  font-size: 16px;
  color: var(--ps-tertiary-color);
  line-height: 22px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
}

.site-header.header-fancy .main-navigation .navbar-nav>li:last-child a:hover {
  background: rgb(48, 78, 186);
}

.main-navigation .navbar-nav>li:last-child>a:before {
  display: none;
}



#notfound {
  position: relative;
  height: 100vh
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center
}

.notfound .notfound-404 {
  position: relative;
  height: 240px
}

.notfound .notfound-404 h1 {
  font-family: montserrat, sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px
}

.notfound .notfound-404 h1>span {
  text-shadow: -8px 0 0 #fff
}

.notfound .notfound-404 h3 {
  font-family: cabin, sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px
}

.notfound h2 {
  font-family: cabin, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  margin-bottom: 25px
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px
  }

  .notfound .notfound-404 h1 {
    font-size: 200px
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px
  }

  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px
  }

  .notfound h2 {
    font-size: 16px
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;

}

.uc-main-title {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 8rem;
  text-shadow:
    1px 0px 1px #ccc, 0px 1px 1px #eee,
    2px 1px 1px #ccc, 1px 2px 1px #eee,
    3px 2px 1px #ccc, 2px 3px 1px #eee,
    4px 3px 1px #ccc, 3px 4px 1px #eee,
    5px 4px 1px #ccc, 4px 5px 1px #eee,
    6px 5px 1px #ccc, 5px 6px 1px #eee,
    7px 6px 1px #ccc;
}

.uc-main-title h1 {
  font-size: 7rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #555;
}

.uc-main-title h2 {
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
}

.svg-img {
  display: block;
  margin: auto;
}

svg {
  display: block;
  margin: auto;
}

#clock {
  animation: clockHand 5s infinite linear;


  transform-box: fill-box;
  transform-origin: bottom;
}

#leftTree,
#righTree {
  animation: tree 2s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#man {
  animation: manBody 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#pc-circle {
  fill: #6ace66;
  stroke-width: 4;
  animation: change-light 4s linear infinite alternate;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: #f5f5f5;
  font-size: 1.3rem;
}

@keyframes clockHand {
  from {
    transform: rotateZ(0deg);
  }

  from {
    transform: rotateZ(-360deg);
  }
}

@keyframes manBody {
  from {
    transform: rotateX(0deg);
  }

  to {
    transform: rotateX(10deg);
  }
}

@keyframes tree {
  from {
    transform: rotateZ(10deg);
  }

  to {
    transform: rotateZ(-20deg);
  }
}

@keyframes change-light {
  0% {
    stroke: #cd61f8;
  }

  25% {
    stroke: #6ace66;
  }

  75% {
    stroke: #2995c0;
  }

  100% {
    stroke: #e92949;
  }
}



@media (min-width: 640px) {
  .uc-main-title h1 {
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #555;
  }

  .uc-main-title h2 {
    font-size: 3rem;
    font-weight: 300;
    text-transform: uppercase;
  }


}

@media (min-width: 768px) {
  .uc-main-title h1 {
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #555;
  }

  .uc-main-title h2 {
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
  }


}

@media (min-width: 1024px) {

  .uc-main-title h1 {
    font-size: 7rem;
    text-transform: uppercase;
    font-weight: 900;
    color: #555;
  }

  .uc-main-title h2 {
    font-size: 5rem;
    font-weight: 300;
    text-transform: uppercase;
  }

}

@media (min-width: 1200px) {

  .uc-main-title h1 {
    font-size: 8rem;
    text-transform: uppercase;
    font-weight: 900;
    color: #555;
  }

  .uc-main-title h2 {
    font-size: 5rem;
    font-weight: 300;
    text-transform: uppercase;
  }

  .uc-main-title {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }


}
.modal-header {
  background: rgb(4,35,104);
  background: linear-gradient(90deg, rgba(4,35,104,1) 5%, rgba(0,0,0,1) 100%);
}
.modal-title.h4 {
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  padding: 10px 18px;
}
button.btn-close {
  position: relative;
  right: 12px;
  background-color: #fff;
}
.btn{
  font-size: initial!important;
}
.form-control-wrap input, 
.form-control-wrap select, 
.form-control-wrap textarea {
  width: 100%;
  height: auto;
  padding: 15px 20px;
  background: transparent;
  outline: 0;
  border: 2px solid #dfe6ed;
  font-size: 14px;
  font-weight: normal !important;
  vertical-align: top;
  outline: 0;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
}
.form-control-wrap input, 
.form-control-wrap select, 
.form-control-wrap textarea {
  padding: 15px 0;
  border: 0;
  border-bottom: 2px solid #dfe6ed;
}
[type=button]:not(:disabled), 
[type=reset]:not(:disabled), 
[type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
.form-control-wrap input:focus,
.form-control-wrap textarea:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}
.form-control-wrap input:focus, 
.form-control-wrap textarea:focus {
  border-color: #042368 !important;
}
.form-control-btn.submit {
  display: inline-block;
  background: var(--ps-primary-color);
  color: var(--ps-white-color);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: 24px;
  font-weight: 600;
  padding: 10px 35px;
  cursor: pointer;
  border: 2px solid var(--ps-primary-color);
  border-radius: 3px;
  transition: all .3s ease-in-out 0s;
}
.form-control-btn.submit {
  background: #042368 !important;
  border: 2px solid #042368 !important;
}
.form-control-btn.submit:hover, 
.form-control-btn.submit:focus {
  background-color: #fff !important;
  color: #042368 !important;
}