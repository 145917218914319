
@font-face {
	font-family: 'Linearicons-Free';
	src:url('../../fonts/linearicons/Linearicons-Free.eot?7y0ygv');
	src:url('../../fonts/linearicons/Linearicons-Free.eot?#iefix7y0ygv') format('embedded-opentype'),
		url('../../fonts/linearicons/Linearicons-Free.woff2?7y0ygv') format('woff2'),
		url('../../fonts/linearicons/Linearicons-Free.woff?7y0ygv') format('woff'),
		url('../../fonts/linearicons/Linearicons-Free.ttf?7y0ygv') format('truetype'),
		url('../../fonts/linearicons/Linearicons-Free.svg?7y0ygv#Linearicons-Free') format('svg');
	font-weight: normal;
	font-style: normal;
}

.lnr {
	font-family: 'Linearicons-Free';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	

	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	-o-font-feature-settings: "liga";
	font-feature-settings: "liga";


	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
	content: "\e800";
}
.lnr-apartment:before {
	content: "\e801";
}
.lnr-pencil:before {
	content: "\e802";
}
.lnr-magic-wand:before {
	content: "\e803";
}
.lnr-drop:before {
	content: "\e804";
}
.lnr-lighter:before {
	content: "\e805";
}
.lnr-poop:before {
	content: "\e806";
}
.lnr-sun:before {
	content: "\e807";
}
.lnr-moon:before {
	content: "\e808";
}
.lnr-cloud:before {
	content: "\e809";
}
.lnr-cloud-upload:before {
	content: "\e80a";
}
.lnr-cloud-download:before {
	content: "\e80b";
}
.lnr-cloud-sync:before {
	content: "\e80c";
}
.lnr-cloud-check:before {
	content: "\e80d";
}
.lnr-database:before {
	content: "\e80e";
}
.lnr-lock:before {
	content: "\e80f";
}
.lnr-cog:before {
	content: "\e810";
}
.lnr-trash:before {
	content: "\e811";
}
.lnr-dice:before {
	content: "\e812";
}
.lnr-heart:before {
	content: "\e813";
}
.lnr-star:before {
	content: "\e814";
}
.lnr-star-half:before {
	content: "\e815";
}
.lnr-star-empty:before {
	content: "\e816";
}
.lnr-flag:before {
	content: "\e817";
}
.lnr-envelope:before {
	content: "\e818";
}
.lnr-paperclip:before {
	content: "\e819";
}
.lnr-inbox:before {
	content: "\e81a";
}
.lnr-eye:before {
	content: "\e81b";
}
.lnr-printer:before {
	content: "\e81c";
}
.lnr-file-empty:before {
	content: "\e81d";
}
.lnr-file-add:before {
	content: "\e81e";
}
.lnr-enter:before {
	content: "\e81f";
}
.lnr-exit:before {
	content: "\e820";
}
.lnr-graduation-hat:before {
	content: "\e821";
}
.lnr-license:before {
	content: "\e822";
}
.lnr-music-note:before {
	content: "\e823";
}
.lnr-film-play:before {
	content: "\e824";
}
.lnr-camera-video:before {
	content: "\e825";
}
.lnr-camera:before {
	content: "\e826";
}
.lnr-picture:before {
	content: "\e827";
}
.lnr-book:before {
	content: "\e828";
}
.lnr-bookmark:before {
	content: "\e829";
}
.lnr-user:before {
	content: "\e82a";
}
.lnr-users:before {
	content: "\e82b";
}
.lnr-shirt:before {
	content: "\e82c";
}
.lnr-store:before {
	content: "\e82d";
}
.lnr-cart:before {
	content: "\e82e";
}
.lnr-tag:before {
	content: "\e82f";
}
.lnr-phone-handset:before {
	content: "\e830";
}
.lnr-phone:before {
	content: "\e831";
}
.lnr-pushpin:before {
	content: "\e832";
}
.lnr-map-marker:before {
	content: "\e833";
}
.lnr-map:before {
	content: "\e834";
}
.lnr-location:before {
	content: "\e835";
}
.lnr-calendar-full:before {
	content: "\e836";
}
.lnr-keyboard:before {
	content: "\e837";
}
.lnr-spell-check:before {
	content: "\e838";
}
.lnr-screen:before {
	content: "\e839";
}
.lnr-smartphone:before {
	content: "\e83a";
}
.lnr-tablet:before {
	content: "\e83b";
}
.lnr-laptop:before {
	content: "\e83c";
}
.lnr-laptop-phone:before {
	content: "\e83d";
}
.lnr-power-switch:before {
	content: "\e83e";
}
.lnr-bubble:before {
	content: "\e83f";
}
.lnr-heart-pulse:before {
	content: "\e840";
}
.lnr-construction:before {
	content: "\e841";
}
.lnr-pie-chart:before {
	content: "\e842";
}
.lnr-chart-bars:before {
	content: "\e843";
}
.lnr-gift:before {
	content: "\e844";
}
.lnr-diamond:before {
	content: "\e845";
}
.lnr-linearicons:before {
	content: "\e846";
}
.lnr-dinner:before {
	content: "\e847";
}
.lnr-coffee-cup:before {
	content: "\e848";
}
.lnr-leaf:before {
	content: "\e849";
}
.lnr-paw:before {
	content: "\e84a";
}
.lnr-rocket:before {
	content: "\e84b";
}
.lnr-briefcase:before {
	content: "\e84c";
}
.lnr-bus:before {
	content: "\e84d";
}
.lnr-car:before {
	content: "\e84e";
}
.lnr-train:before {
	content: "\e84f";
}
.lnr-bicycle:before {
	content: "\e850";
}
.lnr-wheelchair:before {
	content: "\e851";
}
.lnr-select:before {
	content: "\e852";
}
.lnr-earth:before {
	content: "\e853";
}
.lnr-smile:before {
	content: "\e854";
}
.lnr-sad:before {
	content: "\e855";
}
.lnr-neutral:before {
	content: "\e856";
}
.lnr-mustache:before {
	content: "\e857";
}
.lnr-alarm:before {
	content: "\e858";
}
.lnr-bullhorn:before {
	content: "\e859";
}
.lnr-volume-high:before {
	content: "\e85a";
}
.lnr-volume-medium:before {
	content: "\e85b";
}
.lnr-volume-low:before {
	content: "\e85c";
}
.lnr-volume:before {
	content: "\e85d";
}
.lnr-mic:before {
	content: "\e85e";
}
.lnr-hourglass:before {
	content: "\e85f";
}
.lnr-undo:before {
	content: "\e860";
}
.lnr-redo:before {
	content: "\e861";
}
.lnr-sync:before {
	content: "\e862";
}
.lnr-history:before {
	content: "\e863";
}
.lnr-clock:before {
	content: "\e864";
}
.lnr-download:before {
	content: "\e865";
}
.lnr-upload:before {
	content: "\e866";
}
.lnr-enter-down:before {
	content: "\e867";
}
.lnr-exit-up:before {
	content: "\e868";
}
.lnr-bug:before {
	content: "\e869";
}
.lnr-code:before {
	content: "\e86a";
}
.lnr-link:before {
	content: "\e86b";
}
.lnr-unlink:before {
	content: "\e86c";
}
.lnr-thumbs-up:before {
	content: "\e86d";
}
.lnr-thumbs-down:before {
	content: "\e86e";
}
.lnr-magnifier:before {
	content: "\e86f";
}
.lnr-cross:before {
	content: "\e870";
}
.lnr-menu:before {
	content: "\e871";
}
.lnr-list:before {
	content: "\e872";
}
.lnr-chevron-up:before {
	content: "\e873";
}
.lnr-chevron-down:before {
	content: "\e874";
}
.lnr-chevron-left:before {
	content: "\e875";
}
.lnr-chevron-right:before {
	content: "\e876";
}
.lnr-arrow-up:before {
	content: "\e877";
}
.lnr-arrow-down:before {
	content: "\e878";
}
.lnr-arrow-left:before {
	content: "\e879";
}
.lnr-arrow-right:before {
	content: "\e87a";
}
.lnr-move:before {
	content: "\e87b";
}
.lnr-warning:before {
	content: "\e87c";
}
.lnr-question-circle:before {
	content: "\e87d";
}
.lnr-menu-circle:before {
	content: "\e87e";
}
.lnr-checkmark-circle:before {
	content: "\e87f";
}
.lnr-cross-circle:before {
	content: "\e880";
}
.lnr-plus-circle:before {
	content: "\e881";
}
.lnr-circle-minus:before {
	content: "\e882";
}
.lnr-arrow-up-circle:before {
	content: "\e883";
}
.lnr-arrow-down-circle:before {
	content: "\e884";
}
.lnr-arrow-left-circle:before {
	content: "\e885";
}
.lnr-arrow-right-circle:before {
	content: "\e886";
}
.lnr-chevron-up-circle:before {
	content: "\e887";
}
.lnr-chevron-down-circle:before {
	content: "\e888";
}
.lnr-chevron-left-circle:before {
	content: "\e889";
}
.lnr-chevron-right-circle:before {
	content: "\e88a";
}
.lnr-crop:before {
	content: "\e88b";
}
.lnr-frame-expand:before {
	content: "\e88c";
}
.lnr-frame-contract:before {
	content: "\e88d";
}
.lnr-layers:before {
	content: "\e88e";
}
.lnr-funnel:before {
	content: "\e88f";
}
.lnr-text-format:before {
	content: "\e890";
}
.lnr-text-format-remove:before {
	content: "\e891";
}
.lnr-text-size:before {
	content: "\e892";
}
.lnr-bold:before {
	content: "\e893";
}
.lnr-italic:before {
	content: "\e894";
}
.lnr-underline:before {
	content: "\e895";
}
.lnr-strikethrough:before {
	content: "\e896";
}
.lnr-highlight:before {
	content: "\e897";
}
.lnr-text-align-left:before {
	content: "\e898";
}
.lnr-text-align-center:before {
	content: "\e899";
}
.lnr-text-align-right:before {
	content: "\e89a";
}
.lnr-text-align-justify:before {
	content: "\e89b";
}
.lnr-line-spacing:before {
	content: "\e89c";
}
.lnr-indent-increase:before {
	content: "\e89d";
}
.lnr-indent-decrease:before {
	content: "\e89e";
}
.lnr-pilcrow:before {
	content: "\e89f";
}
.lnr-direction-ltr:before {
	content: "\e8a0";
}
.lnr-direction-rtl:before {
	content: "\e8a1";
}
.lnr-page-break:before {
	content: "\e8a2";
}
.lnr-sort-alpha-asc:before {
	content: "\e8a3";
}
.lnr-sort-amount-asc:before {
	content: "\e8a4";
}
.lnr-hand:before {
	content: "\e8a5";
}
.lnr-pointer-up:before {
	content: "\e8a6";
}
.lnr-pointer-right:before {
	content: "\e8a7";
}
.lnr-pointer-down:before {
	content: "\e8a8";
}
.lnr-pointer-left:before {
	content: "\e8a9";
}
.lnr-uni21:before {
	content: "\21";
}
.lnr-uni22:before {
	content: "\22";
}
.lnr-uni23:before {
	content: "\23";
}
.lnr-uni24:before {
	content: "\24";
}
.lnr-uni25:before {
	content: "\25";
}
.lnr-uni26:before {
	content: "\26";
}
.lnr-uni27:before {
	content: "\27";
}
.lnr-uni28:before {
	content: "\28";
}
.lnr-uni29:before {
	content: "\29";
}
.lnr-uni2a:before {
	content: "\2a";
}
.lnr-uni2b:before {
	content: "\2b";
}
.lnr-uni2c:before {
	content: "\2c";
}
.lnr-uni2d:before {
	content: "\2d";
}
.lnr-uni2e:before {
	content: "\2e";
}
.lnr-uni2f:before {
	content: "\2f";
}
.lnr-uni30:before {
	content: "\30";
}
.lnr-uni31:before {
	content: "\31";
}
.lnr-uni32:before {
	content: "\32";
}
.lnr-uni33:before {
	content: "\33";
}
.lnr-uni34:before {
	content: "\34";
}
.lnr-uni35:before {
	content: "\35";
}
.lnr-uni36:before {
	content: "\36";
}
.lnr-uni37:before {
	content: "\37";
}
.lnr-uni38:before {
	content: "\38";
}
.lnr-uni39:before {
	content: "\39";
}
.lnr-uni3a:before {
	content: "\3a";
}
.lnr-uni3b:before {
	content: "\3b";
}
.lnr-uni3c:before {
	content: "\3c";
}
.lnr-uni3d:before {
	content: "\3d";
}
.lnr-uni3e:before {
	content: "\3e";
}
.lnr-uni3f:before {
	content: "\3f";
}
.lnr-uni40:before {
	content: "\40";
}
.lnr-uni41:before {
	content: "\41";
}
.lnr-uni42:before {
	content: "\42";
}
.lnr-uni43:before {
	content: "\43";
}
.lnr-uni44:before {
	content: "\44";
}
.lnr-uni45:before {
	content: "\45";
}
.lnr-uni46:before {
	content: "\46";
}
.lnr-uni47:before {
	content: "\47";
}
.lnr-uni48:before {
	content: "\48";
}
.lnr-uni49:before {
	content: "\49";
}
.lnr-uni4a:before {
	content: "\4a";
}
.lnr-uni4b:before {
	content: "\4b";
}
.lnr-uni4c:before {
	content: "\4c";
}
.lnr-uni4d:before {
	content: "\4d";
}
.lnr-uni4e:before {
	content: "\4e";
}
.lnr-uni4f:before {
	content: "\4f";
}
.lnr-uni50:before {
	content: "\50";
}
.lnr-uni51:before {
	content: "\51";
}
.lnr-uni52:before {
	content: "\52";
}
.lnr-uni53:before {
	content: "\53";
}
.lnr-uni54:before {
	content: "\54";
}
.lnr-uni55:before {
	content: "\55";
}
.lnr-uni56:before {
	content: "\56";
}
.lnr-uni57:before {
	content: "\57";
}
.lnr-uni58:before {
	content: "\58";
}
.lnr-uni59:before {
	content: "\59";
}
.lnr-uni5a:before {
	content: "\5a";
}
.lnr-uni5b:before {
	content: "\5b";
}
.lnr-uni5c:before {
	content: "\5c";
}
.lnr-uni5d:before {
	content: "\5d";
}
.lnr-uni5e:before {
	content: "\5e";
}
.lnr-uni5f:before {
	content: "\5f";
}
.lnr-uni60:before {
	content: "\60";
}
.lnr-uni61:before {
	content: "\61";
}
.lnr-uni62:before {
	content: "\62";
}
.lnr-uni63:before {
	content: "\63";
}
.lnr-uni64:before {
	content: "\64";
}
.lnr-uni65:before {
	content: "\65";
}
.lnr-uni66:before {
	content: "\66";
}
.lnr-uni67:before {
	content: "\67";
}
.lnr-uni68:before {
	content: "\68";
}
.lnr-uni69:before {
	content: "\69";
}
.lnr-uni6a:before {
	content: "\6a";
}
.lnr-uni6b:before {
	content: "\6b";
}
.lnr-uni6c:before {
	content: "\6c";
}
.lnr-uni6d:before {
	content: "\6d";
}
.lnr-uni6e:before {
	content: "\6e";
}
.lnr-uni6f:before {
	content: "\6f";
}
.lnr-uni70:before {
	content: "\70";
}
.lnr-uni71:before {
	content: "\71";
}
.lnr-uni72:before {
	content: "\72";
}
.lnr-uni73:before {
	content: "\73";
}
.lnr-uni74:before {
	content: "\74";
}
.lnr-uni75:before {
	content: "\75";
}
.lnr-uni76:before {
	content: "\76";
}
.lnr-uni77:before {
	content: "\77";
}
.lnr-uni78:before {
	content: "\78";
}
.lnr-uni79:before {
	content: "\79";
}
.lnr-uni7a:before {
	content: "\7a";
}
.lnr-uni7b:before {
	content: "\7b";
}
.lnr-uni7c:before {
	content: "\7c";
}
.lnr-uni7d:before {
	content: "\7d";
}
.lnr-uni7e:before {
	content: "\7e";
}
.lnr-copyright:before {
	content: "\a9";
}
