@import "rsuite/dist/rsuite.css";
@import url('./assets/css/bootstrap.min.css');
@import url('./assets/css/fonts/all.min.css');
@import url('./assets/css/fonts/themify-icons.css');
@import url('./assets/css/fonts/simple-line-icons.css');
@import url('./assets/css/fonts/linearicons.css');
@import url('./assets/css/fonts/ionicons.css');
@import url('./assets/css/fonts/font-awesome.min.css');
@import url('./assets/css/fonts/flaticon.css');

:root {
  --ps-primary-color: #042368 !important;
  --ps-primary-gradient-color: #042368 !important;
  --ps-primary-second-color: #030313 !important;
  --ps-secondary-color: #030313 !important;
  --ps-tertiary-color: #858585 !important;
}

p {
  text-align: justify;
}

#page {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

/* MenuBar */
.sidebar-menu {
  width: 100%;
  position: relative;
  z-index: 99;
  top: 70px;
  left: 0;
  height: auto;
  background-color: rgb(65 61 61 / 68%);
  backdrop-filter: blur(4px);
  display: none;
}

.sidebar-menu.active {
  display: block;
}

.rs-dropdown-item-submenu.rs-dropdown-item>.rs-dropdown-menu {
  position: relative !important;
  top: -6px !important;
  left: 0;
}

.rs-nav .rs-dropdown>.rs-dropdown-toggle {
  height: auto !important;
}

.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: #222733 !important;
  color: #fff !important;
}

.menu-toggle {
  display: none;
}



.DesktopMenu {
  display: none;
}


.MobileMenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  transition: left 0.3s ease;
}

.MobileMenu.open {
  left: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 998;
}

.Overlay.open {
  display: block;
}

/* End MenuBar */

.site-content section {
  padding: 60px 30px 60px 30px;
}

.header-banner {
  position: relative;
  min-height: 90vh;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header-bg {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-bg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  opacity: .6;
}

.header-title-section {
  position: relative;
}

.header-title-section .title-tagline {
  font-weight: 900;
}

.header-title-section .title-tagline:before {
  display: none;
}

img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.get-the-app-section {
  position: relative;
  width: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: var(--ps-primary-color);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 70px 55px 70px 55px !important;
  margin: -60px 60px 0px 60px;
  border-radius: 15px 15px 15px 15px;
}

section.popular-feature {
  background-color: #101010;
  margin: 0px 0px;
}

section.popular-feature .section-title .title-tagline,
section.popular-feature .section-title .main-title {
  color: #fff;
}

section.popular-feature .section-title .title-tagline::before {
  border-color: transparent transparent #fff transparent;
}

section.popular-feature .cs-feature-card .feature-box {
  background-color: #fff;
  box-shadow: unset;
}

section.popular-feature .row-1 .feature-box {
  width: 100%;
  max-width: 297.9px;
  margin: 0px 0px 0px 14px;
  float: left;
}

section.popular-feature .row-2 .cs-feature-card .feature-box {
  width: 100%;
  max-width: 310px;
}

.feature-box .feature-icon i {
  color: #FFFFFF;
  font-size: 50px;
}

.feature-box .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 0px;
  letter-spacing: 0;
}

.feature-box .feature-info p {
  font-size: 28px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 0;
}

.button-wrapper {
  min-width: 160px;
  margin: 0px 20px;
  display: flex;
  justify-content: end;
}

.btn-default .webvite-button-inner {
  display: flex;
  align-items: center;
}

.btn-default .webvite-button-icon.webvite-align-icon-right {
  order: 2;
}

.btn-default .webvite-button-text {
  order: 1;
}

.btn-default.style-default,
.btn-default.style-border,
.btn-default.style-flat {
  vertical-align: top;
}

.btn-default.btn-full-width.style-border,
.btn-default.btn-full-width.style-flat {
  width: 100%;
}

.btn-default.style-border.btn-top-hover,
.btn-default.style-flat.btn-top-hover {
  position: relative;
  top: 0;
}

.btn-default.style-border.btn-top-hover:hover,
.btn-default.style-flat.btn-top-hover:hover {
  top: -7px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.08);
}

.btn-default.style-default {
  display: inline-block;
  font-size: 15px;
  color: var(--ps-primary-color);
  line-height: 30px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}

.btn-default.style-default:before {
  content: "";
  height: 2px;
  width: 0;
  position: absolute;
  left: auto;
  bottom: 0;
  right: 0;
  -webkit-transition: width 300ms ease 0s, left 300ms ease 0s;
  -moz-transition: width 300ms ease 0s, left 300ms ease 0s;
  transition: width 300ms ease 0s, left 300ms ease 0s;
}

.btn-default.style-default:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  height: 2px;
}

.btn-default.style-default:after,
.btn-default.style-default:before {
  background: var(--ps-primary-color);
}

.btn-default.style-default:hover:before {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-default.style-default .webvite-align-icon-left,
.btn-default.style-border .webvite-align-icon-left,
.btn-default.style-flat .webvite-align-icon-left {
  padding-right: 13px;
}

.btn-default.style-default .webvite-align-icon-right,
.btn-default.style-border .webvite-align-icon-right,
.btn-default.style-flat .webvite-align-icon-right {
  padding-left: 13px;
}

.btn-default.style-border {
  display: inline-flex;
  justify-content: center;
  position: relative;
  border: 2px solid #fff;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 40px;
  overflow: hidden;
  transition: all 0.25s ease-in-out 0s;
  padding: 10px 50px 10px 50px;
  border-radius: 30px 30px 30px 30px;
  border-color: #FFFFFF;
}

.btn-default.style-border:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #fff;
  transition: all 0.25s ease-in-out 0s;
}

.btn-default.style-border .webvite-button-inner {
  position: relative;
  z-index: 2;
}

.btn-default.style-border:hover {
  color: #000;
}

.btn-default.style-border.hover-default:before {
  top: 0;
  left: 0;
  opacity: 0;
}

.btn-default.style-border.hover-default:hover:before {
  opacity: 1;
}

.btn-default.style-border.icon-animation {
  transition: inherit;
}


.btn-default.style-border.hover-slide-left:before,
.btn-default.style-flat.hover-slide-left:before {
  width: 0;
  top: 0;
  right: 0;
  left: auto;
  -webkit-transition: width 300ms ease 0s, left 300ms ease 0s;
  -moz-transition: width 300ms ease 0s, left 300ms ease 0s;
  transition: width 300ms ease 0s, left 300ms ease 0s;
}

.btn-default.style-border.hover-slide-left:hover:before,
.btn-default.style-flat.hover-slide-left:hover:before {
  width: 100%;
  left: 0;
  right: auto;
}


.btn-default.style-border.hover-slide-right:before,
.btn-default.style-flat.hover-slide-right:before {
  width: 0;
  top: 0;
  left: 0;
  right: auto;
  -webkit-transition: width 300ms ease 0s, left 300ms ease 0s;
  -moz-transition: width 300ms ease 0s, left 300ms ease 0s;
  transition: width 300ms ease 0s, left 300ms ease 0s;
}

.btn-default.style-border.hover-slide-right:hover:before,
.btn-default.style-flat.hover-slide-right:hover:before {
  width: 100%;
  left: auto;
  right: 0;
}

.btn-default.style-border.hover-slide-top:before,
.btn-default.style-flat.hover-slide-top:before {
  height: 0;
  top: auto;
  bottom: 0;
  left: 0;
  -webkit-transition: height 300ms ease 0s, top 300ms ease 0s;
  -moz-transition: height 300ms ease 0s, top 300ms ease 0s;
  transition: height 300ms ease 0s, top 300ms ease 0s;
}

.btn-default.style-border.hover-slide-top:hover:before,
.btn-default.style-flat.hover-slide-top:hover:before {
  height: 100%;
  top: 0;
  bottom: auto;
}


.btn-default.style-border.hover-slide-bottom:before,
.btn-default.style-flat.hover-slide-bottom:before {
  height: 0;
  top: 0;
  bottom: auto;
  left: 0;
  -webkit-transition: height 300ms ease 0s, top 300ms ease 0s;
  -moz-transition: height 300ms ease 0s, top 300ms ease 0s;
  transition: height 300ms ease 0s, top 300ms ease 0s;
}

.btn-default.style-border.hover-slide-bottom:hover:before,
.btn-default.style-flat.hover-slide-bottom:hover:before {
  height: 100%;
  top: auto;
  bottom: 0;
}

.btn-default.style-flat {
  display: inline-flex;
  justify-content: center;
  position: relative;
  background: #fff;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 40px;
  overflow: hidden;
  transition: all 0.3s ease-in-out 0s;
}

.btn-default.style-flat .webvite-button-inner {
  position: relative;
  z-index: 2;
}

.btn-default.style-flat:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #fff;
  transition: all 0.25s ease-in-out 0s;
}

.btn-default.style-flat.hover-default:before {
  top: 0;
  left: 0;
  opacity: 0;
}

.btn-default.style-flat.hover-default:hover:before {
  opacity: 1;
}


.about-section {
  padding: 120px 0 20px 0;
}

.image-parallax-wrapper {
  width: 120px;
  height: 120px;
  position: absolute;
}

.section-title .title-tagline {
  display: inline-block;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: var(--ps-primary-color);
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 10px;
  padding-left: 20px;
  text-transform: uppercase;
}

.section-title .title-tagline,
.list.icon-default li i {
  color: #042368;
}

.section-title .title-tagline:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent var(--ps-primary-color) transparent;
  position: absolute;
  left: 0;
  top: 3px;
}

.section-title .title-tagline:before {
  border-color: transparent transparent var(--ps-primary-color) transparent;
}

.about-section .btn-default.style-flat {
  background: #042368;
  font-size: 15px;
  color: #fff !important;
  padding: 12px 50px 12px 50px;
  border-radius: 29px 29px 29px 29px;
}

.about-section .btn-default.style-flat:before {
  background-color: #030313;
}


.cs-feature-card .feature-info .title,
.cs-feature-card .feature-info p {
  color: #000;
}

.cs-feature-card .feature-info p {
  font-size: initial;
  color: #858585;
  font-weight: normal;
  text-align: center;
}

.cs-feature-card .feature-box {
  width: 100%;
  max-width: 300px;
  min-height: 355px;
  box-shadow: #f1f1f1 0 10px 42px;
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cs-feature-card .feature-box .feature-icon {
  margin-bottom: 20px;
}

.cs-feature-card .feature-box .feature-icon img {
  width: 70px;
}

.feature-box h5.title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.ps-services-slider .fancy-box {
  position: relative;
}

.ps-services-slider .fancy-box .fancy-box-inner {
  display: flex;
}

.ps-services-slider .fancy-box .fancy-box-img {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.ps-services-slider .fancy-box .fancy-box-img .fancy-img {
  height: 100%;
}

.ps-services-slider .fancy-box .fancy-box-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all .25s ease-in-out 0s;
}

.ps-services-slider .fancy-box:hover .fancy-box-img img {
  transform: scale(1.05);
}

.ps-services-slider .fancy-box .fancy-box-info {
  position: relative;
  width: 470px;
  min-height: 500px;
  z-index: 2;
  padding: 60px;
  padding-top: 100px;
  margin-left: 30px;
  background: var(--ps-primary-second-color);
  display: flex;
  flex: none;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 12px;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-bg-icon {
  position: absolute;
  right: -40px;
  top: -40px;
  opacity: .1;
  font-size: 250px;
  line-height: normal;
  color: var(--ps-primary-color);
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-icon i,
.ps-services-slider .fancy-box .fancy-box-info .fancy-bg-icon {
  color: #fff;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-bg-icon {
  position: absolute;
  right: -40px;
  top: -40px;
  opacity: .1;
  font-size: 250px;
  line-height: normal;
  color: var(--ps-primary-color);
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-icon i,
.ps-services-slider .fancy-box .fancy-box-info .fancy-bg-icon {
  color: #fff;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-icon {
  display: block;
  padding: 0;
  margin-bottom: 25px;
  transition: all .3s ease-in-out 0s;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-icon i {
  font-size: 70px;
  line-height: normal;
  color: var(--ps-primary-color);
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-icon i,
.ps-services-slider .fancy-box .fancy-box-info .fancy-bg-icon {
  color: #fff;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-category {
  display: block;
  color: var(--ps-primary-color);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-title {
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 15px;
  transition: all .3s ease-in-out 0s;
}

.ps-services-slider .fancy-box-title {
  padding: 0;
  margin: 0;
}

.ps-services-slider .fancy-box .fancy-box-info .fancy-box-content {
  color: #cfcfcf;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  transition: all .25s ease-in-out 0s;
}

.according-wrap .accordion-item {
  border: unset;
}

.according-wrap .accordion-item:nth-child(2) {
  border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.according-wrap .accordion-button {
  font-size: 18px;
}

.site-footer .footer-main {
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

.site-footer .widget-column {
  margin-top: 40px;
}

.site-footer .widget+.widget {
  margin-top: 20px;
}

.site-footer .widget:not(:last-child) {
  margin-bottom: 0px;
}

.site-footer .widget h4.widget-title {
  font-size: 22px;
}

.site-footer .widget .widget-title,
.site-footer .widget_feature_info .widget-feature-info .title {
  color: #121212;
}

.site-footer .widget .widget-title {
  margin-bottom: 25px;
}

.site-footer .important-links ul {
  list-style: none;
  display: inline-flex;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget ul li:not(:last-child) {
  margin-bottom: 8px;
}

.widget ul li>a {
  display: inline-block;
  color: var(--ps-tertiary-color);
  line-height: 26px;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
}

.book-demo-form {
  padding: 15px 20px;
}

.site-footer a,
.site-footer .widget ul li>a,
.site-footer .widget_recent_post .recent-post-list .post-info .post-title,
.site-footer .footer-main .widget_calendar .wp-calendar-nav a {
  color: #858585;
}

.site-footer .footer-main:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.site-footer .footer-main:before {
  background-color: rgba(239, 239, 239, 1);
}

@media(max-width:920px) {
  .sidebar-menu {
    display: block;
    position: absolute;
    right: 10px;
    width: 20px;
    left: unset;
    top: 20px;
    background-color: transparent;
  }

  .site-content-contain {
    padding: 70px 0 0px 0 !important;
  }

  .menu-toggle {
    display: block;
  }

  .header-fancy .header-inner .main-navigation,
  .header-fancy .header-inner .info-call {
    display: none !important;
  }

  .header-fancy .header-inner {
    justify-content: space-between;
  }

  .header-fancy .header-inner .header-logo {
    width: 100%;
    display: flex !important;
    margin-left: 12px !important;
    justify-content: space-between;
  }

  .get-the-app-section {
    padding: 22px 28px;
  }

  .get-the-app-section .feature-info p {
    text-align: center;
  }

  .get-the-app-section .feature-box+.vr {
    display: none;
  }

  .button-wrapper {
    min-width: 160px;
    margin: 0px 20px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    width: 100%;
  }

  .button-wrapper .btn-icon {
    display: flex;
    justify-content: center;
  }

  section.popular-feature .row-1 .feature-box {
    margin: 12px 0px;
    max-width: 100%;
  }

  section.popular-feature .row-2 .cs-feature-card .feature-box {
    width: 100%;
    max-width: 100%;
  }

  .popular-feature .row-2 .cs-feature-card {
    margin: 0px 0px 28px 0px;
  }

  .ps-services-slider .fancy-box .fancy-box-info {
    width: 100%;
    margin-left: 0px;
    padding: 25px;
  }

  .action-button-wrap {
    position: relative;
    margin: 0 auto 20px auto;
  }

  .header-title-section p {
    text-align: center;
    padding: 0px 10px;
    margin-top: 20px;
  }

  .site-content section {
    padding: 32px 10px;
    margin: 0px -5px;
  }

  .header-bg img {
    max-width: 100%;
    height: 100%;
  }

  section.about-section .button-wrapper {
    margin: 0 auto;
    max-width: 230px;
  }

  .rs-sidenav-nav .rs-sidenav-item {
    padding: 0px !important;
  }

  .rs-sidenav-item a,
  .rs-sidenav-item span {
    color: #96999f;
    display: block;
    padding: 14px 20px;
    user-select: none;
  }

  .rs-sidenav-item.rs-sidenav-item-active a {
    color: #fff;
  }

  .important-links {
    text-align: left;
  }

  .site-logo img,
  .sticky-site-logo img {
    height: 38px;
  }
}

@media (min-width: 921px) {
  .menu-toggle {
    display: none;
  }

  .MobileMenu {
    display: block;
  }

  .DesktopMenu {
    display: block;
  }

  .rs-sidenav-default {
    display: none !important;
  }
}

.privacy-policy-wrap {
  color: #000;
}

.privacy-policy-wrap section {
  padding: 60px 30px 0px 30px;
}

.privacy-policy-wrap strong {
  color: #000;
}

/* Visitor Form */
.visitor-feed {
  position: relative;
  max-width: 380px;
  margin: 80px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  border: #e9e9e9 solid 2px;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 30px;
}

.visitor-feed .invalid-feedback {
  display: block !important;
}

.visitor-feed input,
.visitor-feed textarea,
.visitor-feed select {
  appearance: revert;
  font-size: 16px;
  padding: 12px 12px;
  background-color: #fff;
  border: 0;
  border: 2px solid #dfe6ed;
  border-radius: 6px;
}

.visitor-feed form label {
  font-size: 12px;
  color: #000;
}

.visitor-feed .is-invalid,
.visitor-feed .is-invalid:focus,
.visitor-feed .is-invalid:focus-within {
  border-width: 1px 1px 1px 10px;
  border-style: solid;
  background: rgb(251, 236, 242);
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem #dc354540 !important;
  appearance: initial;
}

.visitor-feed .uploadImage {
  width: 160px;
  height: 160px;
  background-color: #fff;
  border: 2px solid #dfe6ed;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  margin: -74px auto 0 auto;
}

/* OTP Verification UI */
.otpVerification {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

@media(max-width:480px) {
  .otpVerification {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 99;

  }
}

.otpVerification .text-color {
  color: #0c6814;
}
.otpVerification .mobileNum {
  font-weight: bold;
  font-size: 20px;
}
.otpVerification .card {
  width: 100%;
  background: #fff;
  position: relative;
  border: 2px solid #dfe6ed;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 31%) 0px 10px 40px;
}

.otpVerification .mobile-text {
  color: #212121;
  font-size: 15px;
}

.otpVerification .form-control {
  margin-right: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  max-height: 50px;
}

.otpVerification .form-control:focus {
  color: #495057;
  background-color: #d4efdf;
  border-color: #3F51B5;
  outline: 0;
  box-shadow: none;
}

.otpVerification .cursor {
  cursor: pointer;
}

.main-navigation .navbar-nav > li > a.navitem{
  padding: 3px 10px;
  position: relative;
}
.main-navigation .navbar-nav > li > a.navitem.is-active:before{
  content: "";
  background-color: var(--ps-primary-color);
  width: 100%;
  height: 5px;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 2px;
  opacity: 0.2;
  -webkit-transition: width 300ms ease 0s, left 300ms ease 0s;
  -moz-transition: width 300ms ease 0s, left 300ms ease 0s;
  transition: width 300ms ease 0s, left 300ms ease 0s;
}

.main-content section {
  padding: 60px 30px 60px 30px;
}
.site-content-contain {
  min-height: 84vh;
}